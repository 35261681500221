import { Component, Vue } from "vue-property-decorator";
import { GET_DASHBOARD, GET_PROFILE_AGENT } from "../../../api/account"
import Model from "@/models/Model";
import moment from 'moment';

@Component({
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        sCurrency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})
default class Home extends Vue {
    private model: Model;

    private cookieData: any = Vue.$cookies.get("login")
    public baseUrl = (process.env.NODE_ENV === 'production') ? process.env.VUE_APP_BASE_URL : 'http://localhost:8081'
    private dashboard = {
        summary: {
            amountToday: 0,
            validAmountToday: 0,
            memberWinloseToday: 0,
            agentWinloseToday: 0,
            amountYesterday: 0,
            validAmountYesterday: 0,
            memberWinloseYesterday: 0,
            agentWinloseYesterday: 0,
            countMember: 0,
            countAgent: 0,
            newMemberToday: 0,
            newMemberLastweek: 0,
            newMemberLastMonth: 0,
            totalOutstandingBet: 0,
            totalOutStandingBalance: 0,
            totalPlayer: 0,
            totalPlayerRoom1: 0,
            totalPlayerRoom2: 0,
            totalPlayerRoom3: 0,
            totalPlayerRoom4: 0,
            totalPlayerRoom5: 0,
            dataGraphMonthWinLose : [],
            dataGraphMonthTurnOver : []
        },
        wallet: {
            seamless: 0,
            transfer: 0
        }
    }
    private chartOption = {}
    private chartOption2 = {}
    private profile = {}

    constructor() {
        super();
        this.model = new Model();
    }
    async mounted() {
        this.addRouteName()
        await this.getProfile()
    }

    private async getProfile() {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });

        if (this.cookieData) {
            const result = await GET_PROFILE_AGENT(this.cookieData.realUsername)
            this.profile = result[0]
        }

        const result = await GET_DASHBOARD(this.cookieData.username)
        if (result.success) {
            this.dashboard.summary = result.data

            this.chartOption = {
                title: {
                    text: `${this.$t('BALANCE_INFO').toString()}`
                },
                exporting: {
                    enabled: false
                },
                subtitle: {
                    text: `${this.$t('MONTH_GRAPH').toString()} ${this.getWordMonth(moment().format('MM'))} ${moment().format('YYYY')}`
                },
                yAxis: {
                    title: {
                        enabled: false
                    }
                },
                credits: {
                    enabled: false
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'top'
                },
                plotOptions: {
                    series: {
                        pointStart: 1
                    }
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: "มูลค่า: {point.y:,.0f}",
                },
                series: [{
                    name: `<span>${this.$t('DASHBOARD.WINLOSE').toString()}</span>`,
                    data: this.dashboard.summary.dataGraphMonthWinLose,
                    color: '#CFA137'
                }, {
                    name: `<span>${this.$t('DASHBOARD.TURNOVER').toString()}</span>`,
                    data: this.dashboard.summary.dataGraphMonthTurnOver,
                    color: '#5fa76f'
                }]
            }
            

            this.chartOption2 = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: `<span>${this.$t('DASHBOARD.TOTAL').toString()}</span>`,
                    data: [{
                        name: `<span>${this.$t('DASHBOARD.NO_PLAYER_1').toString()}</span>`,
                        y: this.dashboard.summary.totalPlayerRoom1,
                        color: '#FF9933'
                        
                    }, {
                        name: `<span>${this.$t('DASHBOARD.NO_PLAYER_2').toString()}</span>`,
                        y: this.dashboard.summary.totalPlayerRoom2,
                        color: '#3399FF'
                    }, {
                        name: `<span>${this.$t('DASHBOARD.NO_PLAYER_3').toString()}</span>`,
                        y: this.dashboard.summary.totalPlayerRoom3,
                        color: '#339999'
                    }, {
                        name: `<span>${this.$t('DASHBOARD.NO_PLAYER_4').toString()}</span>`,
                        y: this.dashboard.summary.totalPlayerRoom4,
                        color: '#FF0000'
                    }, {
                        name: `<span>${this.$t('DASHBOARD.NO_PLAYER_5').toString()}</span>`,
                        y: this.dashboard.summary.totalPlayerRoom5,
                        color: '#33CC66'
                    }]
                }]
            }
        }
        loader.hide()
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    getWordMonth(month)
    {
        if(month == '01')
        {
            return `${this.$t('JANUARY').toString()}`
        } else if(month == '02')
        {
            return `${this.$t('FEBRUARY').toString()}`
        } else if(month == '03')
        {
            return `${this.$t('MARCH').toString()}`
        } else if(month == '04')
        {
            return `${this.$t('APIRL').toString()}`
        } else if(month == '05')
        {
            return `${this.$t('MAY').toString()}`
        } else if(month == '06')
        {
            return `${this.$t('JUNE').toString()}`
        } else if(month == '07')
        {
            return `${this.$t('JULY').toString()}`
        } else if(month == '08')
        {
            return `${this.$t('AUGUST').toString()}`
        } else if(month == '09')
        {
            return `${this.$t('SEPTEMBER').toString()}`
        } else if(month == '10')
        {
            return `${this.$t('OCTOBER').toString()}`
        } else if(month == '11')
        {
            return `${this.$t('NOVEMBER').toString()}`
        } else if(month == '12')
        {
            return `${this.$t('DECEMBER').toString()}`
        }
    }
}
export default Home