


































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import HomeComponent from "./Home.component";
@Component
export default class Home extends Mixins(HomeComponent) {}
